<template>
  <div class="brand" v-show="ready">
    <div class="main">
      <div class="block_blind"></div>
      <div class="block_inner">
        <div class="block_content">
          <Preview
            v-for="(work, index) in works"
            :key="index"
            :index="index"
            :doc="work"
            :paire="index%2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
<transition name="projet-transition" mode="out-in"><router-view /></transition>
@click="$router.push('/'+work.display)"
            @click="openPreview(work)"
<Transition name="fade-preview">
<div class="play">
  <a class="playBut" style="display:none;">
    <svg version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
    x="0px" y="0px" width="100px" height="100px" viewBox="0 0 213.7 213.7" enable-background="new 0 0 100 100"
    xml:space="preserve">
      <polygon class="triangle" id="XMLID_18_" fill="none" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
      73.5,62.5 148.5,105.8 73.5,149.1 "/>
      <circle class="circle" id="XMLID_17_" fill="none"  stroke-width="7" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3"/>
    </svg>
  </a>
</div>

            // v-if="preview_open"
            // v-bind:doc="preview_doc"
            // v-on:closePreview="closePreview()"

<div
            class="work_item"
            v-for="(work, index) in works"
            :key="index"
            @click="openProjet('/brand/'+work.display)"
            v-on:mouseover="mouseover(work)"
            v-on:mouseleave="mouseleave(work)"
          >
            <div class="image" :style="'background-image:url(\''+cdn+'/images/works/'+work.image+'.jpg'+'\')'"></div>
            <div class="video" v-show="preview_current.indexOf(work.preview) > -1"><div class="video_inner"><video loop autoplay muted :src="cdn + '/videos/preview/' + work.preview + '.mp4'"></video></div></div>
            <div class="text">
              <div class="font_anton libelle"><span v-html="work.libelle.replace('<br/>', ' ')"></span></div>
              <div class="font_oswald caption"><span v-html="work.caption_1.replace('Brand | ', '').replace('Fiction | ', '')"></span></div>
            </div>
          </div>

*/
import Preview from '@/components/Preview'
import _ from 'lodash'
// import { gsap, Power1 } from 'gsap'
// import ScrollTrigger from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Brand',
  metaInfo () {
    return {
      title: this.$store.state.meta.basic + ' | Brand | Films pour les marques',
      meta: [
        { name: 'robots', content: this.$store.state.meta.robots },
        { name: 'description', content: this.$store.state.meta.description },
        { property: 'og:title', content: this.$store.state.meta.og_title + ' | Brand | Films pour les marques' },
        { property: 'og:site_name', content: this.$store.state.meta.og_site_name },
        { property: 'og:type', content: this.$store.state.meta.og_type },
        { property: 'og:url', content: this.$store.state.meta.og_url + 'brand' },
        { property: 'og:image:type', content: this.$store.state.meta.og_image_type },
        { property: 'og:image', content: this.$store.state.meta.og_image }
      ]
    }
  },
  components: {
    Preview
  },
  data () {
    return {
      header_video: this.$store.state.cdn + '/videos/header/brand.mp4',
      works: null,
      ready: false
      // preview_open: false
      // preview_doc: {},
      // preview_current: []
    }
  },
  computed: {
    works2 () {
      const works = this.$store.state.works
      const brandworks = _.filter(works, function (w) { return w.actif && w.type.indexOf('brand') > -1 })
      const bwWithOrder = _.orderBy(_.filter(brandworks, function (w) { return w.order && w.order > 0 }), function (e) { return parseInt(e.order) }, ['asc'])
      const bwWithoutOrder = _.shuffle(_.filter(brandworks, function (w) { return !w.order || w.order < 1 }))
      const bwConcat = _.concat(bwWithOrder, bwWithoutOrder)
      console.log(bwConcat)
      return bwConcat
      // return _.orderBy(_.filter(works, function (w) { return w.actif && w.type.indexOf('brand') > -1 }), function (e) { return parseInt(e.order) }, ['asc'])
    }
  },
  updated () {
    // if (this.$route.matched.length > 1) {
    //   setTimeout(function () {
    //     document.documentElement.style.overflow = 'hidden'
    //   }, 400)
    //   // document.documentElement.style.overflow = 'hidden'
    // } else {
    //   document.documentElement.style.overflow = 'auto'
    // }
  },
  beforeRouteEnter (to, from, next) {
    console.log('BRAND - BEFORE ROUTE ENTER')
    window.scrollTo(0, 0)
    // this.$store.state.loading_title = 'toto'
    if (from.name === 'home') window.scrollTo(0, 0)
    next(vm => {
      // vm.$store.state.loading_time_after = 1150
      vm.$store.state.loading_time_after = 0
      vm.$store.state.loading_direct = true
    })
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.loading_open = true
    this.$store.state.loading_leave = true
    // LOADING TITLE
    if (to.name === 'home') this.$store.state.loading_title = 'crazymage'
    else if (to.name === 'projet') this.$store.state.loading_title = 'work'
    else this.$store.state.loading_title = to.name
    setTimeout(function () {
      next()
    }, this.$store.state.loading_time_before)
  },
  mounted: function () {
    const self = this
    if (!this.$store.state.loading_first) {
      setTimeout(function () {
        self.$store.state.loading_open = false
      }, this.$store.state.loading_time_after)
    } else {
      this.$store.state.loading_open = false
    }

    // const timeStartAnim = this.$store.state.loading_time_after + 1500
    // const timeStartAnim = 20
    const timeStartAnim = this.$store.state.loading_first ? 2500 : 0
    setTimeout(function () {
      // self.startBigTitleAnim()
      self.ready = true
      self.startPreviews()
    }, timeStartAnim)

    // this.startBigTitleAnim()

    /* gsap.timeline({
      scrollTrigger: {
        trigger: '.block_first',
        start: 'top top',
        end: 'bottom',
        markers: false,
        scrub: true,
        pin: false
      }
    })
      .fromTo('.block_video', { y: 0, opacity: 1 }, { y: -(innerHeight * 0.2), opacity: 0.2 }, 0)
      .fromTo('.block_title', { y: 0, scale: 1 }, { ease: Power0.easeNone, y: -(innerHeight * 0.2), scale: 0.9 }, 0)
      // .fromTo('.navigation', { background: 'none' }, { background: '#000' }, 0) */
  },
  methods: {
    startPreviews () {
      const works = this.$store.state.works
      const brandworks = _.filter(works, function (w) { return w.actif && w.type.indexOf('brand') > -1 })
      const bwWithOrder = _.orderBy(_.filter(brandworks, function (w) { return w.order && w.order > 0 }), function (e) { return parseInt(e.order) }, ['asc'])
      const bwWithoutOrder = _.shuffle(_.filter(brandworks, function (w) { return !w.order || w.order < 1 }))
      const bwConcat = _.concat(bwWithOrder, bwWithoutOrder)
      this.works = bwConcat
    },
    openPreview (work) {
      // console.log(work)
      this.preview_open = true
      this.preview_doc = work
    },
    closePreview () {
      this.preview_open = false
      this.preview_doc = {}
      document.documentElement.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>

.brand {
  padding-bottom: 60px;
}

.block {

  //position: fixed;
  padding-top: 80px;
  position: relative;
  // z-index:-1;
  // background: rgb(22,74,65);
  // background: linear-gradient(90deg, rgba(22,74,65,1) 0%, rgba(157,200,141,1) 100%);
  background: rgb(171,214,153);
  background: radial-gradient(transparent, rgba(0,0,0,0.45)), linear-gradient(132deg, #abd699 0%, #73c9b6 100%);
  //box-shadow: inset 0 0 5em 1em #000;
  top:0px;
  left:0px;
  width:100%;
  height:calc(100vh - 80px); // changer aussi block bind
  color: #fff;

  .block_contain {

    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    position:relative;
    overflow: hidden;

    .bg_video {
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      z-index:0;
      .bg_video_wrapper {
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;
        video {
          -webkit-clip-path: url(#svgPath);
          clip-path: url(#svgPath);
          object-fit: cover;
          width: 100vw;
          height: 100vh;
          top: 0;
          left: 0;
        }
      }
    }

    .lines {
      opacity: 0;
    }
    .line_1 {
      padding-bottom: 1.5em;
      color: #fff;
      font-size: 1.2em;
      line-height: 1em;
      text-transform: uppercase;
    }
    .line_2 {
      padding-top: 1em;
      color: #fff;
      font-size: 1.2em;
      line-height: 1em;
      text-transform: uppercase;
    }

    .block_contain_inner {
      flex-basis: calc(100% - 60px);
      padding: 0 30px;
      text-align: center;
      .block_title {
        opacity: 0;
        transform: translate(0,20px);
        font-size: 5em;
        letter-spacing: 0.04em;
        margin-left: -3.5px;
        line-height: 0.8em;
        /* text-shadow: 0px 3px 0px #b2a98f,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1); */

        //text-shadow: -15px 5px 20px #ced0d3;
        //color: rgba(117,201,183,1);
        letter-spacing: 0.025em;
        color: #a6d59b;
        //text-shadow: 0 13.36px 8.896px #508a7e,0 -2px 1px #fff;
        text-shadow: 0 0px 8.896px #508a7e,0 -2px 1px #fff;
        transition: letter-spacing 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        user-select: none;
        &:hover {
          // letter-spacing: 0.025em !important;
        }
      }
      .block_desc {
        display:none;
        font-size: 1.2em;
        letter-spacing: 0.04em;
        max-width:700px;
        margin: 0 auto;
      }
    }

  }

}

.main {
  .block_blind {
    height:65vh; // mettre comme block fixed
    height: 0;
  }
  .block_inner {
    background-color:#141414;
    padding: 40px 25px;
    padding-bottom: 0px;
    padding-top: 80px;
    .block_content {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

@media screen and (min-width: 42em) {
  .block_inner {
    padding: 40px 40px;
  }
  .block {
    .block_contain {
      .block_contain_inner {
        .block_title {
          font-size: 7em;
        }
        .block_desc {
          font-size: 1.3em;
          line-height: 1.4em;
        }
      }
    }
  }
}

@media screen and (min-width: 60em) {
  .block {
    .block_contain {
      .block_contain_inner {
        .block_title {
          font-size: 8em;
        }
        .block_desc {
          font-size: 1.4em;
          line-height: 1.4em;
        }
      }
    }
  }
}

@media screen and (min-width: 72em) {

}

</style>
